<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
      data-black-overlay="5"
      :style="{ backgroundImage: 'url(' + src + ')' }"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">Modelos e Promotores</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->
    <div id="item.title" v-for="item in items" :key="item.title" class="rn-service-details ptb--120 bg_color--5">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" :order="item.order1" :order-md="item.order1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <span class="subtitle">Our service</span>
                <h2 class="heading-title">{{ item.title }}</h2>
                <p class="description">
                  {{ item.desc1 }}
                </p>
                <p class="description">
                  {{ item.desc2 }}
                </p>
              </div>
              <ul class="list-style--1 mt--30">
                <li v-for="line in item.list" :key="line">
                  <i v-html="iconSvg(listIcon)"></i>
                  {{ line }}
                </li>
              </ul>
            </div>
          </v-col>
          <v-col lg="6" md="6" cols="12" :order="item.order2" :order-md="item.order2">
            <div class="thumb position-relative">
              <img
                class="w-100"
                :src="item.src"
                alt="Service Images"
              />
            </div>
          </v-col>
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo-sm.png"),
        src: require("../../assets/images/service/service.jpeg"),
        listIcon: "check",
        items: [
          {
            title: "Promotor",
            order1: "1",
            order2: "2",
            desc1: "Esses profissionais são especializados em apresentar produtos ou serviços para potenciais compradores, promovendo a empresa de maneira eficaz e direcionada.",
            desc2: "Eles são treinados para identificar e abordar as pessoas que estão mais propensas a se interessar pelo que a empresa tem a oferecer. Além disso, eles podem apresentar produtos ou serviços de forma criativa e atraente, gerando interesse e engajamento por parte dos consumidores.",
            src: require("../../assets/images/service/lg/promotor.jpg"),
            listTitle: "Benefícios ao contratar um Promotor",
            list: [
              "Garante a atenção e envolvimento do público",
              "Aumenta a conscientização sobre sua marca ou causa",
              "Contribui para criar um ambiente de entusiasmo",
              "Aumenta a probabilidade de sucesso do evento",
              "Transmitir mensagens importantes de forma clara e memorável"
            ],
          },
          {
            title: "Recepcionista",
            order1: "2",
            order2: "1",
            desc1: "Recepcionistas são profissionais treinados que são responsáveis por dar as boas-vindas aos convidados e orientá-los durante o evento. Esses profissionais podem ajudar a gerenciar fila de convidados e fornecer informações garantindo que todos sejam atendidos de forma eficiente e rápida.",
            desc2: "Alem disso, ajudam a criar uma atmosfera acolhedora e positiva, proporcionando uma experiência agradável para seus convidados.",
            src: require("../../assets/images/service/lg/recepcionista.jpg"),
            listTitle: "Benefícios ao contratar uma Recepcionista",
            list: [
              "Gerencia a fila de convidados de forma eficiente",
              "Fornece informações importantes sobre o evento",
              "Responde a perguntas dos convidados",
              "Ajuda a tornar o evento mais organizado e eficiente",
              "Cria uma atmosfera acolhedora para seus convidados"
            ],
          },
          {
            title: "Modelo",
            order1: "1",
            order2: "2",
            desc1: "Trabalhamos com uma equipe de profissionais experientes em recrutamento e seleção de modelos, garantindo que apenas os melhores talentos sejam apresentados aos nossos clientes. Com anos de experiência no mercado de moda e publicidade, nossos modelos estão prontos para se destacar e agregar valor à sua marca.",
            desc2: "Nosso casting é treinado para se adaptar a diferentes situações, seja em um desfile de moda de alto nível ou em uma campanha publicitária. Temos o talento certo para atender às suas necessidades e alcançar seus objetivos de marketing.",
            src: require("../../assets/images/service/lg/modelo.jpg"),
            listTitle: "Benefícios ao contratar um Modelo",
            list: [
              "Torna sua marca mais visível e atraente para o público",
              "Atrai mais clientes e aumenta a probabilidade de sucesso",
              "Apresenta e exibe seus produtos de forma atraente",
              "Destacar as características dos produtos apresentados",
              "Aumenta a visibilidade e o reconhecimento da sua marca"
            ],
          },
          {
            title: "Quick Massage",
            order1: "2",
            order2: "1",
            desc1: "Imagine seus colaboradores desfrutando de momentos revitalizantes durante o expediente, permitindo uma pausa revigorante no meio da agitação. Os benefícios são evidentes: redução do estresse, alívio de tensões musculares, melhoria da concentração e aumento da sensação de bem-estar.",
            desc2: "Ao investir no bem-estar dos seus colaboradores, você não apenas eleva o clima, mas também promove um ambiente mais saudável e produtivo. Priorize o conforto e o bem-estar de sua equipe com nosso serviço de Quick Massage.",
            src: require("../../assets/images/service/lg/massage.jpeg"),
            listTitle: "Benefícios ao contratar um Mascote",
            list: [],
          },
          {
            title: "Influencer",
            order1: "1",
            order2: "2",
            desc1: "Além de aumentar a visibilidade da marca nas redes sociais, o influencer pode ajudar a aumentar o engajamento com o público-alvo, gerar confiança e credibilidade na marca e aumentar o número de seguidores e potenciais clientes. Com sua influência, pode endossar e recomendar a sua marca, aumentando a probabilidade de conversão de vendas e posicionando sua empresa como líder de mercado.",
            desc2: "Por isso, se você quer aumentar a presença e o impacto da sua marca nas redes sociais, contratar um influencer pode ser uma estratégia muito eficiente.",
            src: require("../../assets/images/service/lg/influencer.jpg"),
            listTitle: "Benefícios ao contratar um Influencer",
            list: [
              "Aumento da visibilidade da marca nas redes sociais",
              "Maior alcance e engajamento do público-alvo",
              "Aumento do número de seguidores e clientes",
              "Recomendação por parte do influencer",
              "Maior probabilidade de conversão de vendas"
            ],
          },
          {
            title: "Garçon",
            order1: "2",
            order2: "1",
            desc1: "Garçons são treinados para fornecer serviços de alta qualidade, garantindo que seus convidados sejam bem cuidados e atendidos de forma atenciosa e profissional. Eles podem ajudar a garantir que seus convidados tenham uma experiência agradável e sem preocupações, atendendo a todas as suas necessidades de serviço, incluindo o fornecimento de bebidas e alimentos.",
            desc2: "Contratar um garçom ajuda a garantir que seu evento seja um sucesso sem preocupações, permitindo que você e seus convidados desfrutem de um serviço excepcional e personalizado.",
            src: require("../../assets/images/service/lg/garçon.jpg"),
            listTitle: "Benefícios ao contratar um Garçon",
            list: [
              "Fornecem serviços de alta qualidade aos convidados",
              "Ajudam a manter o evento organizado",
              "Aumentam a probabilidade de sucesso do seu evento",
              "Aumenta a satisfação dos clientes",
              "Garantem que seus convidados sejam atendidos de forma atenciosa"
            ],
          },
          {
            title: "Bartender",
            order1: "1",
            order2: "2",
            desc1: "Especialistas em coquetéis e bebidas, os bartenders podem criar bebidas personalizadas de acordo com as preferências de seus convidados, proporcionando uma experiência personalizada e aumentando a satisfação dos clientes.",
            desc2: "Um bartender experiente também pode ajudar a manter o evento organizado e fluido, gerenciando a fila e as demandas dos convidados de forma eficiente.",
            src: require("../../assets/images/service/lg/bartender.jpg"),
            listTitle: "Benefícios ao contratar um Bartender",
            list: [
              "Proporciona uma experiência única para seus convidados",
              "Ajuda a criar uma atmosfera elegante em seu evento",
              "Gerencia a fila dos convidados",
              "Ajuda a manter o evento organizado e fluido",
              "Pode criar bebidas personalizadas de acordo com as preferências dos convidados"
            ],
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .heading-title {
    color: black !important;
  }
</style>
